.sectionToPrint {
  .titleSection {
    display: flex;
    justify-content: space-between;
    .titlePrint {
      visibility: hidden;
      display: inline-block;
    }

    .title {
      display: inline-block;
    }
  }
}

@media print {
  @page {
    size: auto;
    margin: 0;
  }

  html,
  body {
    visibility: hidden;
  }

  .sectionToPrint {
    visibility: visible;
    margin-top: -100px;

    .titleSection {
      display: flex;
      justify-content: center;
      .title {
        display: none;
      }

      .printIcon {
        display: none;
      }

      .addIcon {
        display: none;
      }

      .titlePrint {
        visibility: visible;
      }
    }
  }
}
