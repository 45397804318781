.cardContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 50px;
  justify-content: center;
  margin-top: 20px;

  .titles {
    display: flex;
    justify-content: space-around;
  }

  .card {
    padding: 10px;

    .cardMedia {
      .qrCode {
        width: 256px;
        height: 256px;
        margin-right: 10px;
      }

      .qrCode:last-child {
        margin-right: 0;
      }
    }
  }
}

@media only screen and (max-width: 1250px) {
  .cardContainer {
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (max-width: 850px) {
  .cardContainer {
    grid-template-columns: 1fr;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
  }
}

@media print {
  .cardContainer {
    grid-template-columns: auto;
    margin-top: 40px;

    .card {
      box-shadow: none;
      padding: 0;

      .cardMedia {
        padding: 0;
        margin: 0;

        .qrCode {
          margin-right: 30px;
        }

        .qrCode:last-child {
          margin-right: 0;
        }
      }

      .titles {
        padding: 0;
        margin: 0;
      }

      .cardActions {
        display: none;
      }

      .cardContent {
        .MuiCardContent-root {
          padding: 0;
        }
      }
    }

    .card {
      margin-top: -20px;
    }

    .card:nth-child(4) {
      margin-top: 120px;
    }

    .card:nth-child(3n + 4) {
      margin-top: 120px;
    }

    .card:last-child {
      margin-bottom: 0;
    }
  }
}
